import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './BookDemo.css'


import { Demo } from "./Api"

const demoCtrl = new Demo() ;


function BookDemo()
{

    function handleSubmit(event) {
        event.preventDefault();

        var theForm = document.getElementById('myForm') ;
    
        const data = new FormData(theForm);
    
        const value = Object.fromEntries(data.entries());

        //JSON.stringify(Object.fromEntries(data))
    
        console.log(JSON.stringify(value) );


        console.log( document.getElementById('name4').value) ;

            const mydata = {
                name : document.getElementById( "name4").value ,
                lastname : document.getElementById("lastname4").value ,
                email : document.getElementById( "inputEmail4").value , 
                phone : document.getElementById( "phone4").value , 
                company : document.getElementById( "company4").value , 
                position : document.getElementById( "position4").value , 

            }

        console.log( mydata ) ;

        (async() => {
            await demoCtrl.setDemo( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()
        

    }

    function callBack( response )
    {
        console.log( "response en callBack = " + JSON.stringify( response ) ) ;
    }

    return (



        <div className='book-in'>


            <div className='row pt-5'>

                <div className='text-top col-12 center-block text-center'>
                    Book a Demo
                </div>

            </div>


            <div className='row pt-5 pb-5 align-middle'>

                <div className='col-2'>

                </div>

                <div className='col-3 my-auto ' style={{fontSize:25}}>

                    <p>Thank you for your interest in out online platform. To provide you with personalized experience, we need some additional details. Please fill out the following form to request a demo to our platform. </p>

                </div>



                <div className='col-5 vl'>

                <form id="myForm" onSubmit={handleSubmit}>
                    <div className="form-row">


                    <div className="form-group col-md-5">
                        <label htmlFor="name4">Name</label>
                        <input type="text" className="form-control" id="name4" placeholder="Name"/>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="lastname4">Last Name</label>
                        <input type="text" className="form-control" id="lastname4" placeholder="Last Name"/>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="inputEmail4">Email</label>
                        <input type="email" className="form-control" id="inputEmail4" placeholder="Email"/>
                    </div>
                    <div className="form-group col-md-5">
                        <label htmlFor="phone4">Phone</label>
                        <input type="text" className="form-control" id="phone4" placeholder="Phone"/>
                    </div>
                        <label htmlFor="company4">Company</label>
                        <input type="text" className="form-control" id="company4" placeholder="Company"/>
                    </div>
                    <div>    
                        <label htmlFor="position4">Position</label>
                        <input type="text" className="form-control" id="position4" placeholder="Position"/>
                    </div>

 
                    <button type="submit"  className="btn btn-primary mt-5">Request a Demo</button>

                </form>






                </div>

                <div className='col-3'>

                </div>


            </div>

            {/* <div style={{height:400}}></div> */}
        </div>  

    )

}

export default BookDemo ;