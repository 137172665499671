import './AboutUs.css'

//import { Container } from 'react-bootstrap';

import logohh from './images/logo-hh-desktop.svg'

function AboutUs()
{
    return (
        <div className="about row align-items-center">

            <div className='row '>


                <div className="col-1"></div>
                <div className="col-5 mt-5">

                    <div  style={ {color:'white', fontSize:50}}>
                        About Us<br></br>
                        How it Works<br></br>
                        Price
                        <br></br><br></br>
                    </div>
                    <div className='frame1-1' >
                        <a href='/#/login'>Login </a> <a href='/#/bookdemo'>| Book a Demo</a> 
                    </div>


                </div>

                <div className="frame2 col-5 mt-5 ml-auto" >

                    <div className='frame1-2'>
                        Let's get in touch
                    </div>

                    <div style={{ height : 200 }}>

                    </div>

                    <div className='container float-right'>

                        <div className='mt-5'>
                            <img src={logohh} alt="logohh"/>
                        </div>


                    </div>
                </div>

                <div className="col-1"></div>

            </div> 


            <div className='row'>


                <div className='col-12 text-center'>
                <p style={{color:'white' , fontSize :20 , alignContent:'center'}}>
                                    Hunter&Hire 2023|Aviso de Privacidad|Terms and Conditions|Design by PIXMI 
                </p>

                </div>



            </div>

        </div>

    )

}

export default AboutUs ;