import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './Second.css'

import ficha1 from './images/Ficha1.png' ;
import ficha2 from './images/Ficha2.png' ;


function Second()
{
    return(
        <div>

            <div className='second'>

                <div style={{height : 200}}></div>

                <div className='row'>

                    <div className='col-1'></div>

                    <div className='col-4'>

                        <p className='hunter' style={ {fontSize:30  }} >
                            HunterHire.io is the number one user-frendly marketplafe platform created to have a true win-win between companies and experienced firms & top headhunters around the world
                        </p>

                    </div>

                    <div className='col-7'>

                    <img src={ficha1} alt="ficha1"/>
                    <img src={ficha2} alt="ficha2" />


                    </div>


                </div>

                <div style={{height : 100}}></div>

                <div className='row'>

                    <div className='col-1'>

                    </div>

                    <div className='hunter-2 col-2'>

                        <p>Employers</p>

                    </div>

                    <div className='col-2'></div>

                    <div className='hunter-3 col-6'>

                        <p>We support connecting and managing the best headhunter partners with two import paramaters: real experience of the sector by region and by position, as well as increasing hiring effectiveness in less time.</p>

                    </div>

                    

                    



                </div>

                <hr className='m-5' style={{color:'white'}}></hr>


                <div className='row'>

                    <div className='col-1'>

                    </div>

                    <div className='hunter-2 col-4'>

                        <p>Executive Search firms or HHS</p>

                    </div>

 
                    <div className='hunter-3 col-6'>

                        <p>We support connecting and managing the best headhunter partners with two import paramaters: real experience of the sector by region and by position, as well as increasing hiring effectiveness in less time.</p>

                    </div>


                    </div>


                

            </div>
            

        </div>
    )
}

export default Second ;