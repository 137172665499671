import './Howit.css'

import { useRef , useEffect} from 'react';

import Lottie from "lottie-react";

import howitworks from './images/how-it-works-animatedBack.json'





function Howit () {

    var lottie = useRef(null) ;

    useEffect(() => {
        lottie.current.setSpeed( 0.20) ;
    }) ;

    return (

        <div>
            <div className="Howit">
                <div style={{height : 200}}></div>
                <p className='works'>How it works:</p>

                <div>
                    <Lottie  lottieRef={lottie} animationData={howitworks} loop={true} />;
                </div>
            </div>
        </div>
    ) 
}

export default Howit ;