import {  Route, Routes } from "react-router-dom";


import AppView from "./AppView";
import BookDemo from "./BookDemo";
import AboutUs from './AboutUs'
import Login from './Admin/Login'
import NewMember from "./Admin/NewMember";
import Company from "./Admin/Company";


function App(){

    return (
        <Routes>
            <Route exact path="/" element={<AppView/>} />
            <Route exact path="/bookdemo" element={<BookDemo/>} />
            <Route exact path="/about" element={<AboutUs/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/newmember" element={<NewMember/>} />
            <Route exact path="/company" element={<Company/> } />


        </Routes> 

    )
}

export default App ;