import './Optimizing.css'

import grafica1 from './images/grafica1.png'
import grafica2 from './images/grafica2.png'
import grafica3 from './images/grafica3.png'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';



function Optimizing () {
    return (

        <div>
            <div className="optim">

                <div className='row'>

                    <div className='col-1'>
                    </div>

                    <div className='col-7'>
                        <p className='opsearch' >
                            Optimizing Search Process
                        </p>

                    </div>

                </div>

                <div className='row'>

                    <div className='col-1'>
                    </div>

                    <div className='col-7'>
                        <p className='optext'>

                            Disruptive data analisys engine, Hunter&Hire Smart Machine AI Tecnology and experienced consultants, will work 24/7 for employers and the headhunter ecosystem mazimizing its chances of success.
                        </p>

                    </div>

                    <div className='col-2'>

                        <img src={grafica1} alt="ficha1"/>

                    </div>

                </div>
                <div style={{heigh:40}}>hola</div>
                <div className='row'>
                    <div className='col-1'></div>
                    <div className='col-7'>

                    <p className='optext '>

                        At HunterHire.io up to three most suitable and efficient Suppliers will be assigned per job oppotunity with candidates ready to be delivered. H&H will match its users most relevant experience and historical introduced candidates 

                    </p>

                    

                    <p  className='inline'>
                        &nbsp;with new and future job opportunities to automate and optimize hiring desicion-marking process. When a job opportunity is posted, whithin a few days, solid potential candidates from qualified recruiters with specific expertise in a location, industry or function will be delivered.  
                    </p>

                    </div>

                    <div className='col-2'>
                        
                        <div className='row pr-0 mb-5'>
                            <img  src={grafica2} style={{width:150,height:'auto'}} alt="graph2"/>
                        </div>

                        <div style={{heigh:10}}></div>
                        
                        <div className='row'>
                            <img  src={grafica3} alt="graph3"/>
                        </div>
                        


                    </div>
                </div>
                <div style={{heigh:40}}>hola</div>
                <div className='row'>

                    <div className='col-1'>

                    </div>

                    <div className='col-7'>
                    <p className='optext '>

                        H&H platform ensures effective vendor management and communication bettween all parties throughout theprocess.

                    </p>

                    </div>

                    <div className='col-4'>
                    <div className='buttonalign'>
                        <Button className='buttonInfo btn-lg' href='/#/bookdemo' >Talk to an Advisor </Button>{' '}
                    </div>
                    </div>

                </div>


            </div>
        </div>
    ) ;
}

export default Optimizing ;