import { useState } from 'react';
import { useNavigate} from 'react-router-dom'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './NewMember.css'

import { LoginApi } from "../Api"

const LoginApp = new LoginApi() ;





function NewMember()
{
    const navigate = useNavigate();

    const [errorMessage,setErrorMessage] = useState("") ;

    function handleSubmit(event) {
        event.preventDefault();
 
        const mydata = {
            email : document.getElementById( "email").value ,
            password : document.getElementById("password").value ,

        }

        console.log( mydata ) ;

        (async() => {
            await LoginApp.getLogin( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()

    }

    function callBack( result )
    {
        // Do something
        console.log( "Llegando result " + result + " " + typeof( result ) ) ;

        if ( result===false)
        {
            console.log( "El usuario no esta registrado, todo bien") ; // Aqui debemos de pasar a cargar datos
            //setErrorMessage( "Error en el Login") ;
            var data = {

                email : document.getElementById( "email").value ,
                password : document.getElementById( "password").value 

            }

            navigate( "/company" , {state : data}  ) ;
        }else{
            setErrorMessage( "previously registered email ...") ;
        }
    }


    return (
        <div className="new">

            <div className='row title h-100'>
                <div className='col-12 text-center my-auto'>
                    New Member Registration
                </div>


                <div className='d-flex justify-content-center' style={{color:'white', fontSize:20}}>

                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked />
                        <label className="form-check-label" htmlFor="inlineRadio1">Admin Consultant</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                        <label className="form-check-label" htmlFor="inlineRadio2">Verified User</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                        <label className="form-check-label" htmlFor="inlineRadio3">Unverified User</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" />
                        <label className="form-check-label" htmlFor="inlineRadio4">User Corporate</label>
                    </div>

                    
                </div>

                <div className='d-flex justify-content-center mt-0' style={{color:'white', fontSize:20}}>


                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email address</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <input type="password" className="form-control" id="password" placeholder="Password" />
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>

                        {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}
                    </form>


                </div>

            </div>

        </div>
    )

}

export default NewMember ;