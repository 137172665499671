import { Globals } from "./Globals";

export class Countries{

    //endpoint = 'localhost:3002/bookademo' ;


      async getCountries( cb )
      {


          var url = Globals.urlBack + "/getcountries"

          await fetch(url)
            .then((response) => response.json())
            .then((body) => {
                console.log( JSON.stringify(body));

                cb( body ) ;
                return ; 
            })
            .catch((err) =>{

                console.log( err ) ;

                cb( {}) ;

            }); 

      }

}