import './Testimonials.css'

function Testimonials()
{
    return (
        <div>

            <div className="testimonials">


                <div className='row'>
                    <div className='col-4'></div>
                    <div className='col-4'>
                        <p className='test-text'>
                            Testimonials
                        </p>
                    </div>
                    <div className='col-4'></div>

                </div>

                {/* <div className="container"> */}
                <div className='row'> 

                    <div className='col-1'></div>


                    <div className='bloque col-2'>
                    
                        <p className='text0'>
                            Testimonio1
                        </p>
                    </div>
                    
                    <div className='bloque col-2 mb-3'>
                        <p className='text0'>
                            Testimonio2
                        </p>
                    </div>
                    <div className='bloque col-2'>
                        <p className='text0'>
                            Testimonio3
                        </p>

                    </div>
                    <div className='bloque col-2'>
                        <p className='text0'>
                            Testimonio4
                        </p>

                    </div>
                    <div className='bloque col-2'>
                        <p className='text0'>
                            Testimonio5
                        </p>

                    </div>
                    <div className='col-1'></div>
                    


                </div>
                {/* </div> */}


            </div>

        </div>
    )
}


export default Testimonials ;