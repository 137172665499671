// Damos de alta a una compañia

import { useEffect} from 'react'

import './Company.css'

import { Countries } from "../Api"


const CountryApi = new Countries() ;


function Company( props ){ // Recimimos los datos capturados en la forma

    useEffect( () => {
        //Runs only on the first render

        CountryApi.getCountries( myFunction ) ; // Solo hacemo el llamado con el callback 

      }, []);

    console.log( "Entrando a company" + props.email ) ;

    function myFunction( response )
    {
        console.log( "myFunction => response.length = " + response.length ) ;

        const select = document.getElementById( "mySelect") ;

        response.forEach( (item) => {

            const opt1 = document.createElement("option") ;

            opt1.value = item.id ;
            opt1.text = item.name ;

            select.add( opt1 ) ;

        })

       
    }

    function handleSubmit()
    {

    }


    return (
        <div className='company'>

            <div className='row h-100 m-5'>

            <div className='col-4'></div>


            <div className='col-4 my-auto'>

                <div className='text-center'>
                    <p style={{fontSize:25,color:'white'}}>Capture Company</p>
                </div>

                <div className='frame1'>

                

                <form id="myForm" onSubmit={handleSubmit}>
                    <div className="form-row">


                        <div className="form-outline mb-1"> 
                            <label htmlFor="companyname">Company Name</label>
                            <input type="text" className="form-control" id="companyname" placeholder="Company Name"/>
                        </div>
                        <div className="form-outline mb-1">
                            <label htmlFor="comercialname">Comercial Name</label>
                            <input type="text" className="form-control" id="comercialname" placeholder="Comercial Name"/>
                        </div>

                        <div className="form-outline mb-1">
                            <label htmlFor="description">Description</label>
                            <input type="text" className="form-control" id="description" placeholder="Description"/>
                        </div>

                        <div className="form-outline mb-1">
                            <label htmlFor="email">Email</label>
                            <input type="email" className="form-control" id="email" placeholder="Email"/>
                        </div>
                        <div className="form-outline mb-1">
                            <label htmlFor="website">Web Site</label>
                            <input type="url" className="form-control" id="website" placeholder="WebSite"/>
                        </div>

                        <div className="form-group col-md-3">
                            <label htmlFor="quantityemployees">Employees</label>
                            <input type="number" className="form-control" id="quantityemployees" placeholder="Quantity Employees"/>
                        </div>

                        {/* <div className="form-group col-md-3"> */}
                        <label htmlFor="mySelect">Select a Country</label>
                            <select id="mySelect">Select a Country</select>
                        {/* </div> */}

                        <div className="form-outline mb-3">
                            <label htmlFor="description">Address</label>
                            <input type="text" className="form-control" id="address" placeholder="Address"/>
                        </div>

                        <div className="form-outline mb-3">
                            <label htmlFor="complement">Complement</label>
                            <input type="text" className="form-control" id="complement" placeholder="Complement"/>
                        </div>

                        <div className="form-outline mb-3">
                            <label htmlFor="zipcode">ZipCode</label>
                            <input type="text" className="form-control" id="complement" placeholder="Zip Code"/>
                        </div>

                        <div className="form-outline mb-3">
                            <label htmlFor="city">City</label>
                            <input type="text" className="form-control" id="city" placeholder="City"/>
                        </div>




 
                        <button type="submit"  className="btn btn-primary mt-5">Capture Address</button>

                    </div>

                </form>
                </div>
            </div>

            <div className='col-4'></div>



            </div>


        </div>
    )

}

export default Company ;