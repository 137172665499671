//import logo from './logo.svg';

import logohh from './images/logo-hh-desktop.svg'

import whats from './images/logo-whatsapp.svg'

import iconomenu from './images/icono-menu.svg'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './AppView.css';
//import { Button } from 'bootstrap';
import Button from 'react-bootstrap/Button';

import Second from './Second';
import Howit from './Howit';
import Optimizing from './Optimizing';
import Partners  from './Partners';
import Testimonials from './Testimonials';
import ClimbingUp from './ClimbingUp';
import Footer from './Footer';

function AppView() {
  return (

    <div>

    

    <div className="App App-back" >

        <div className='container-fluid p-5'>

          <div className='row '>

            <div className='col-1 posfix'>
              <img src={logohh} alt="logohh"/>
            </div>

            <div className='col-9'>
            </div>

            <div className='col-1 mr-0'>
              <p className='pt-2' style={ {fontSize:20 , color : 'white' }}>
                En|Sp
              </p>
            </div>

            <div className='col-1 mr-0'>

              <a  href="/#/about"><img src={iconomenu}  alt="menu"/></a>

              
            </div>



          </div>

        </div>
    

      <header className="App-header">

        <p className='mb-1' style={ {fontSize:50}}>
          The most effective LATAM
        </p>

        <p  className='App-text-gradient mt-1' style={{ fontSize:50 , }}>
          headhunting marketplace
        </p>

        <p  style={ {fontSize:50}}>
          platform
        </p>

        <div className='buttonalign'>
          <Button className='buttonInfo' href='/#/bookdemo' >Book demo now! </Button>{' '}
        </div>

        <div className='container'>

          <div className='row'>

            <div className='col-10'>
              
            </div> 

            <div className='col-1  whats mb-4'>
              <img src={whats} alt="whats"/>
            </div>

            <div className='col-1'>
              
            </div>
          </div>

        </div>
        
      </header>


    </div>
      <Second />
      <Howit />
      <Optimizing/>
      <Partners/>
      <Testimonials/>
      <ClimbingUp/>
      <Footer/>
      
    </div>
  );
}

export default AppView;
