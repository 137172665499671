import Button from 'react-bootstrap/Button';
import './ClimbingUp.css'

function ClimbingUp() {

    return (
        <div className="climb">

            <div className="row">
                <div className="col-12">
                    <p className="cli-text-1">Climbing Up</p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p className="cli-text-2">Hunter & Hire Ranks</p>
                </div>
            </div>

            <div className='row'>
                <div className="col-4">
                </div>
                <div className="col-2">
                    <p className="cli-text-3">I'm a</p>
                </div>
                <div className="col-2">
                    <p className="cli-text-3">We are a</p>
                </div>
                <div className="col-4">
                </div>
            </div>

            <div className='row align-self-end gx-0'>
                <div className="col-4">
                </div>
                <div className="col-2 ">
                    <Button className='buttonInfo-1 w-100'>Employeer</Button>
                </div>
                <div className="col-2">
                    <Button className='buttonInfo-2 w-100'>Executive Search Firms or HHS</Button>
                </div>
                <div className="col-4">
                </div>
            </div>


            <div className='row mt-5'>

                <div className='col-2'></div>

                <div className='col-4 center-block'>
                    <p className='cli-text-4'>Hiring Pro</p>
                </div>

                <div className='col-4 center-block'> 
                    <p className='cli-text-4'>Stafing Elite</p>
                </div>
                <div className='col-2'></div>


            </div>

            <div className='row gx-5' mt-3>

                <div className='col-2'></div>

                <div className='div-1 col-4'>

                    <p className='div-text-1'>3 Months</p>
                    <p className='div-text-2'>Additional warranty pero position</p>

                </div>

                <div className='div-2 col-4'>

                    <p className='div-text-1'>5 Months</p>
                    <p className='div-text-2'>Additional warranty per position</p>


                </div>

                <div className='col-2'></div>

            </div>


        </div>
    )
}

export default ClimbingUp ;