
import { Globals } from "./Globals";

export class LoginApi{

    //endpoint = 'localhost:3002/bookademo' ;


      async getLogin( jsonData , cb ){

        console.log( "json data = " +  JSON.stringify( jsonData ) ) ;

          var url = Globals.urlBack + "/login"
  
          await fetch(url, {  // Enter your IP address here
  
              method: 'POST', 
              //mode: 'cors', 
              headers: {
                "content-type": "application/json",  
                //"Accept": "application/json"
              },
              body:  JSON.stringify( jsonData  ) // body data type must match "Content-Type" header
    
        }).then(res => res.json()) 
        .then( (response ) =>{
  
          console.log( "Ya tenemos la respuesta " +  response ) ;

          cb(response ) ;
  
          return   ;
  
        }).catch((err)=>{
  
          console.log( err) ;
  
        } )
        .finally(() =>{
            console.log( "Ya terminamos") ;

            return ;
        }) ;
  
      }
  

}