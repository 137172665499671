import logohh from './images/logo-hh-desktop.svg'
import './Footer.css'

function Footer()
{
    return (
        <div className='footer'>

                <div className='container-fluid p-5'>

                    <div className='row '>

                    <div className='col-1'>
                        <img src={logohh} alt="logohh"/>
                    </div>

                    </div>

                    <div className='row'>

                        <div className='col-5'>

                        </div>

                        <div className='col-7 pr-0'>

                            <p style={{color:'white' , fontSize :20 }}>
                                Hunter&Hire 2023|Aviso de Privacidad|Terms and Conditions|Design by PIXMI 
                            </p>

                        </div>


                    </div>
                </div>
        </div>
    )
}

export default Footer ;