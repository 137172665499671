
import './Partners.css'

function Partners()
{
    return (
        <div>

            <div className='partners'>

                <div className='row'>

                    <div className='col-1'></div>

                    

                    <div className='col-4'>
                        

                        <div className='row'>

                            <p className='part-text pt-5'>
                                Our Partners

                            </p>

                        </div>
                        <div className='row'>

                        <p className='part-text-2 pt-5'>
                                We are proud to have some of the leading companies in their industry as partners. Together, we create synergies thet allow us to increase effectiveness and efficient in the talent search.

                            </p>


                        </div>

                    </div>

                    <div className='col-2'></div>
                    <div className='col-2'></div>
                    <div className='col-2'></div>
                    <div className='col-1'></div>


                </div>

            </div>

        </div>
    )
}

export default Partners ;