
import { useState } from 'react'

import './Login.css'

import { LoginApi } from "../Api"

const LoginApp = new LoginApi() ;

function Login()
{
    const [errorMessage,setErrorMessage] = useState("") ;

    function handleSubmit(event) {
        event.preventDefault();

        var theForm = document.getElementById('myForm') ;
    
        const data = new FormData(theForm);
    
        const value = Object.fromEntries(data.entries());

        //JSON.stringify(Object.fromEntries(data))
    
        console.log(JSON.stringify(value) );


 
        const mydata = {
            email : document.getElementById( "email").value ,
            password : document.getElementById("password").value ,

        }

        console.log( mydata ) ;

        (async() => {
            await LoginApp.getLogin( mydata , callBack)

            console.log( "Ya termino el setDemo")

            //console.log(  response  ) ;
        })()
        

    }

    function callBack( result )
    {
        // Do something
        console.log( "Llegando result " + result + " " + typeof( result ) ) ;

        if ( result===false)
        {
            console.log( "pintamos el error") ;
            setErrorMessage( "Error en el Login") ;
        }else{
            setErrorMessage( "Usuario , registrado con exito") ;
        }
    }


    return (
        <div className="login">

            <div className='row h-100'>

                <div className='col-4'></div>


                <div className='col-4 my-auto'>

                    <div className='text-center'>
                        <p style={{fontSize:25,color:'white'}}>Login</p>
                    </div>

                    <div className='frame1'>

                    

                    <form id='myForm' onSubmit={handleSubmit}>
                    
                    <div className="form-outline mb-4">
                        <input type="email" id="email" className="form-control" />
                        <label className="form-label" htmlFor="form2Example1">Email address</label>
                    </div>

                    
                    <div className="form-outline mb-4">
                        <input type="password" id="password" className="form-control" />
                        <label className="form-label" htmlFor="form2Example2">Password</label>
                    </div>

                    
                    <div className="row mb-4">
                        <div className="col d-flex justify-content-center">
                        
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="form2Example31"  />
                            <label className="form-check-label" htmlFor="form2Example31"> Remember me </label>
                        </div>
                        </div>

                        <div className="col">
                        
                        <a href="#!">Forgot password?</a>
                        </div>
                    </div>

                    
                    <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block mb-4">Sign in</button>

                    {errorMessage && <div className="frame3 text-center"> {errorMessage} </div>}

                    
                    <div className="text-center">
                        <p>Not a member? <a href="/#/newmember">Register</a></p>
                        <p>or sign up with:</p>
                        <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-facebook-f"></i>
                        </button>

                        <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-google"></i>
                        </button>

                        <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-twitter"></i>
                        </button>

                        <button type="button" className="btn btn-link btn-floating mx-1">
                        <i className="fab fa-github"></i>
                        </button>
                    </div>
                    </form>
                    </div>
                </div>

                <div className='col-4'></div>



            </div>


        </div>
    )



}

export default Login ;